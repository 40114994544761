html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
  width: 100%;
  color: #404040;
  background-color: #e6e6e6;
  font-family: 'Verdana', sans-serif;
  --background-color: #73809b;
  --highlight-color: #c8f1c8;
  --highlight-border-color: #98f398;
}

@media print {

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0 auto 0 auto;
  }
}

/* 

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  max-width: 21cm;
  align-self: center;
  text-align: left;
  display: grid;
  grid-template:
    "header main" 1fr
    "footer main" auto
    / 1.2fr 2fr;
  background-color: white;
}

header,
footer {
  background-color: #73809b;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: start;
  padding: 2em 0 2em 2em;
}

footer {
  padding-top: 1em;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  padding: 2em 0 2em 2em;
}

.photo-container {
  margin: 0 3em 0 0;
  padding: 0;
  height: 16vmin;
  position: relative;
  align-self: center;
}

.photo {
  margin: 0;
  padding: 0;
  width: auto;
  height: 16vmin;
  border-radius: 100%;
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 2.6em;
  margin: 0;
  padding: 0;
  line-height: 16vmin;
  letter-spacing: -2px;
}

h2 {
  letter-spacing: 2px;
}

header h2,
footer h2 {
  border-color: #FFF;
}

header section,
footer section {
  margin-bottom: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */