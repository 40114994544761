.section-element.section-technologie {
  flex-direction: column;
  align-items: start;
  margin: 0 2em 1em 0;
}

.section-icon {}

.technologie-name {
  font-size: 11px;
  margin-bottom: .4em;
}

.technologie-knowledge {
  border: 2px solid white;
  height: 1em;
  width: 100%;
}

.techonlogie-filler {
  background-color: white;
  display: block;
  height: 100%;
}

.knowledge-0 .techonlogie-filler {
  width: 0%;
}

.knowledge-1 .techonlogie-filler {
  width: 10%;
}

.knowledge-2 .techonlogie-filler {
  width: 20%;
}

.knowledge-3 .techonlogie-filler {
  width: 30%;
}

.knowledge-4 .techonlogie-filler {
  width: 40%;
}

.knowledge-5 .techonlogie-filler {
  width: 50%;
}

.knowledge-6 .techonlogie-filler {
  width: 60%;
}

.knowledge-7 .techonlogie-filler {
  width: 70%;
}

.knowledge-8 .techonlogie-filler {
  width: 80%;
}

.knowledge-9 .techonlogie-filler {
  width: 90%;
}

.knowledge-10 .techonlogie-filler {
  width: 100%;
}