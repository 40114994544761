/**** name ****/

.starter-name {
  margin-left: 1em;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.starter-name:hover {
  color: #c8f1c8;
  transition: color 0.2s ease-in-out;
}

.starter-name:active {
  color: white;
}

.starter-name.highlighted {
  color: #c8f1c8;
  cursor: default;
}

/**** pkmn animation ****/

.starter-container {
  margin-top: -.4em;
  position: relative;
  overflow: hidden;
}

.starter-container svg {
  position: absolute;
}

.starter-pkmn,
.starter-pkball,
.starter-star {
  opacity: 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.starter-pkmn {
  width: 5.2em;
  filter: grayscale(50%);
  margin-top: 1.4em;
  position: absolute;
  top: -10px;
  left: 120px;
  z-index: 3;
}

.starter-pkball {
  width: 1.6em;
  height: 1.6em;
  filter: grayscale(50%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.starter-star {
  width: 1.6em;
  height: 1.6em;
  filter: grayscale(50%);
  position: absolute;
  top: 50px;
  left: 80px;
  z-index: 1;
}


.starter-counter {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  line-height: 1em;
  margin-left: .5em;
  padding: 0 auto 0 auto;
  text-align: center;
  word-wrap: normal;
  border-radius: 50%;
  color: var(--highlight-color);
  border: 1px solid var(--highlight-border-color);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.starter-counter.visible {
  opacity: 100;
  transition: opacity 0.3s ease-in-out;
}