html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px;
  width: 100%;
  color: #404040;
  background-color: #e6e6e6;
  font-family: 'Verdana', sans-serif;
}

@media print {

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0 auto 0 auto;
  }

  .App {
    min-width: 100vw;
    height: 100vh;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  max-width: 21cm;
  align-self: center;
  text-align: left;
  display: grid;
  grid-template:
    "header main" 1fr
    "footer main" auto
    / 1.2fr 2fr;
  background-color: white;
}

header,
footer {
  background-color: #73809b;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-items: start;
  padding: 2em 0 2em 2em;
}

footer {
  padding-top: 1em;
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  padding: 2em 0 2em 2em;
}

.photo-container {
  margin: 0 3em 0 0;
  padding: 0;
  height: 16vmin;
  position: relative;
  align-self: center;
  border-radius: 50%;
  overflow: clip;
}

.photo {
  margin: 0;
  padding: 0;
  width: auto;
  height: 16vmin;
  border-radius: 100%;
  scale: 1.4;
}

h1,
h2,
h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 2.6em;
  margin: 0;
  padding: 0;
  line-height: 16vmin;
  letter-spacing: -2px;
}

h2 {
  letter-spacing: 2px;
}

header h2,
footer h2 {
  border-color: #FFF;
}

header section,
footer section {
  margin-bottom: 1em;
}



.App.mobile {
  min-width: 100vw;
  align-self: center;
  text-align: left;
  display: grid;
  grid-template:
    "header" 1fr
    "main" auto
    "footer" auto
    / auto;
  background-color: white;
}

.mobile header {
  display: flex;
  flex-direction: row;
  padding: 1em;
}

.mobile .photo-container {
  margin: 0 1em 0 0;
  padding: 0;
  height: auto;
  position: relative;
  align-self: center;
  flex-shrink: 1;
}

.mobile .photo {
  margin: 0;
  padding: 0;
  width: auto;
  height: 40vw;
  border-radius: 100%;
}