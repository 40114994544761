.container {
  position: relative;
  overflow: hidden;
  display: flex;
  font-size: 1.4em;
  height: calc(1.1em + .9em + 1.6em + 2px);
  width: 100%;
}

.text-roll {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.text-roll.rolling {
  animation: move 1.2s forwards;
}

.text-roll>* {
  position: relative;
  animation: white-out 1s;
}

h2 {
  font-size: 1em;
  padding: .4em 0 .5em 0;
  margin: .8em 0 .8em 0;
  line-height: 1.1em;
  width: 100%;
  border-bottom: 2px solid #000;
  text-align: start;
  display: block;
}

@keyframes move {
  100% {
    transform: translatey(calc(-1 * (1.1em + .9em + 1.6em + 2px)));
    opacity: 1;
  }
}