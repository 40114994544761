h2 {
  font-size: 1.4em;
  width: 100%;
  padding: .4em 0 .4em 0;
  border-bottom: 2px solid #000;
}

h3 {
  font-size: 1em;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

a {
  color: white;
  display: block;
  text-decoration: none;
}

section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1em 1em 0;

}

.section-icon-container {
  display: inline-block;
  height: 2em;
  margin-left: 1em;
  position: relative;
}

.section-icon-container-spin {
  display: inline-block;
  height: 2em;
  position: relative;
  transform-style: preserve-3d;
}

@media (hover: hover) {
  .section-icon-container-spin:hover {
    animation: spin 2.6s ease-in-out .8s infinite;
  }
}

.section-icon-container-spin:active {
  animation: none;
}

/* .section-icon-container-spin:hover .section-icon,
.section-icon-container-spin:hover .section-icon-back {
  box-sizing: border-box;
  outline: 2px solid var(--highlight-border-color);
  border-radius: 50%;
  outline-offset: -2px;
} */

.section-icon,
.section-icon-back {
  filter: grayscale(25%);
  height: 2em;
  transition: outline 0.2s linear;
  transition-delay: .8s;
}

.section-icon {
  transform: translateZ(1px);
}

.section-icon-back {
  position: absolute;
  top: 0;
  left: 0;
}

.section-icon.pointer {
  cursor: pointer;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.section-lines {
  margin-left: 1em;
}

.section-role {
  font-size: 1.1em;
}

.section-dates {
  font-style: italic;
}